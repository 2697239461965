
  .home-container {
    background-image: url('Original_Painting_of_Supreme_Court_Building_2442x.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh; /* Ensures the background covers the full viewport height */
    display: flex;
    flex-direction: column;
  }
  